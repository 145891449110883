.d2-container {
    width: 1000px;
    margin: 30px auto; /* center the website */
    background-color: lightgray;

    display: grid;
    grid-template-rows: 100px 150px 300px 100px; /* 4 rows height.*/ /*repeat(5, 1fr);*/
    grid-template-columns: repeat(4, 1fr) 200px; /* sidebar is of 200px */
    grid-gap: 20px;
}

.d2-container > * {
    /* all direct children */
    /*border: 1px solid;*/
    background-color: #144272;
    color: #fff;
    padding: 20px;
    font-size: 20px;
    font-family: sans-serif;
}

.d2-header {
    grid-column: 1 / -1;
    background-color: #0A2647;
    color: #fff;
}
.d2-box {
    background-color: #144272;
}
.d2-footer {
    grid-row: 4 / 5;
    grid-column: 1 / -1;
    background-color: #2C74B3;
}
.d2-main {
    grid-row: 3 / 4;
    grid-column: 1 / 5; /* span 3 can also be used here */

    background-color: #205295;
}
.d2-side-bar {
    grid-row: 2 / 4; /* span 2 can also be used here */
    grid-column: 5 / 6;
}