.design-header {
  width: 1000px;
  margin: 30px auto;
  margin-bottom: -45px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  /*background-color: rgb(8, 84, 109);*/
  /*color: white;*/
  font-size: 28px;
}

.footer-line {
  border-bottom: 1px solid; 
  margin-top: 5px;
  width: 1000px;
  margin: 1px auto;
}

.design-main-page-footer {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 25px;
  text-align: center;
  font-size: 12px;
}

.design-main-page-footer strong {
  font-weight: 600;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
