.d1-container {
    width: 1000px;
    margin: 30px auto; /* center the website */
    background-color: lightgray;

    display: grid;
    grid-template-rows: 100px 150px 300px 100px; /* 4 rows height.*/ /*repeat(5, 1fr);*/
    grid-template-columns: 200px repeat(2, 1fr); /* sidebar is of 200px */
    grid-gap: 20px;
}

.d1-container > * {
    /* all direct children */
    /*border: 1px solid;*/
    /*background-color: lightseagreen;  */
    padding: 20px;
    font-size: 20px;
    font-family: sans-serif;
}

.d1-header {
    grid-column: 1 / -1;
    background-color: #243763;
    color: #fff;
}
.d1-box {
    background-color: #FF6E31;
}
.d1-footer {
    grid-row: 4 / 5;
    grid-column: 1 / -1;

    background-color: #AD8E70;
    color: #fff;
}
.d1-main {
    grid-row: 3 / 4;
    grid-column: 2 / -1; 

    background-color: #FFEBB7;
}
.d1-side-bar {
    grid-row: 2 / 4; /* span 2 can also be used here */
    grid-column: 1 / 2;

    background-color: #E6E2C3;
}