.d3-container {
    width: 1000px;
    margin: 30px auto; /* center the website */
    background-color: lightgray;

    display: grid;
    grid-template-rows: 100px 150px 300px 100px; /* 4 rows height.*/ /*repeat(5, 1fr);*/
    grid-template-columns: 200px repeat(2, 1fr) 200px; /* sidebar is of 200px */
    grid-gap: 20px;
}

.d3-container > * {
    /* all direct children */
    /*border: 1px solid;*/
    background-color: lightseagreen;        
    padding: 20px;
    font-size: 20px;
    font-family: sans-serif;
}

.d3-header {
    grid-column: 1 / -1;
}
.d3-footer {
    grid-row: 4 / 5;
    grid-column: 1 / -1;
}
.d3-main {
    grid-row: 3 / 4;
    grid-column: 2 / 4; 
}
.d3-side-bar-left {
    grid-row: 2 / 4; /* span 2 can also be used here */
    grid-column: 1 / 2;
}
.d3-side-bar-right {
    grid-row: 2 / 4;
}
